import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAxios } from "../axios-provider";
import {
  ApiError,
  SANITIZE_OPTS_ALLOW_TAGS,
  SANITIZE_OPTS_NO_TAGS,
  ValidProductAttributeWithValue,
  ValidProductType,
  isProductAttribute,
  isProductType,
} from "../utils";
import { components } from "../data/products.types";
import { RoleContext } from "../role-provider";
import { getProductDetails, saveProductDetails } from "../data/products";
import { Loader } from "./loader/Loader";
import { LanguageSelector } from "./LanguageSelector";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { useNavigate } from "react-router-dom";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { UnsavedChangesModal } from "./UnsavedChangesModal";
import DOMPurify from "dompurify";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

type ProductType = components["schemas"]["ProductTypeResponse"];
type ProductAttribute = components["schemas"]["elementDetails"];

type ProductMarketingFormInput = {
  product_id: string
  product_type: string;
  product_name: string;
  product_marketing_name: string;
  genesis_product_name: string;
  product_external_id: string;
  product_wrin_no: string;
  ingredient_statement: string;
  product_allergen: string;
  product_additional_allergen: string;
  product_comments: string;
  product_additional_text_ingredient_statement: string;
  product_keywords: string;
  attach_product_thumbnail_image: string;
  imported_product_name: string;
  import_id: string;
};

type ProductFocusableInputFields = {
  ingredient_statement: string;
  product_allergen: string;
  product_additional_allergen: string;
  product_comments: string;
  product_additional_text_ingredient_statement: string;
  product_keywords: string;
};

type ValidProductAttribute = {
  keyName?: string;
  isEditable?: string;
};

type ProductMarketingFormProps = {
  productId?: string;
  editable: boolean;
  isLive: boolean;
  saveSuccessMessage: string;
  language: string;
  setLanguage: (language: string) => void;
  onSaveSuccess?: () => void;
};
export const ProductMarketingForm = ({
  productId,
  editable,
  isLive,
  saveSuccessMessage,
  language,
  setLanguage,
  onSaveSuccess,
}: ProductMarketingFormProps) => {
  const handleSaveSuccess =
    typeof onSaveSuccess === "function" ? onSaveSuccess : () => { };
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const { apiClient } = useAxios();
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const getDefaultValues = (
    attributes: ValidProductAttributeWithValue[]
  ): ProductMarketingFormInput => {
    const defaultValues: Partial<ProductMarketingFormInput> = {};
    attributes.forEach((attr) => {
      defaultValues[attr.keyName as keyof ProductMarketingFormInput] =
        attr.value ?? "";
    });
    return defaultValues as ProductMarketingFormInput;
  };
  const { data, isFetching } = useCustomQuery(
    [
      "getProductDetails",
      {
        productId,
        countryCode: selectedCountry,
        languageCode: language,
      },
    ],
    () =>
      getProductDetails(apiClient)({
        productId: productId !== undefined ? productId : undefined,
        countryCode: selectedCountry!,
        languageCode: language!,
        roleId: selectedRole!,
      })
  );
  const productTypeOptions = useMemo(() => {
    if (data?.data.typeList === undefined) {
      return [];
    }
    const validProductTypes = data?.data.typeList.filter(
      (maybeProductType): maybeProductType is ValidProductType => {
        return isProductType(maybeProductType) === true;
      }
    );
    return validProductTypes.map((type: ProductType) => ({
      value: type?.value,
      id: type?.id,
    }));
  }, [data]);

  const [focusedFields, setFocusedFields] = useState({
    ingredient_statement: false,
    product_allergen: false,
    product_additional_text_ingredient_statement: false,
    product_additional_allergen: false,
    product_comments: false,
    product_keywords: false,
  });
  const handleFieldFocus = (fieldName: string, isFocused: boolean) => {
    setFocusedFields((prevFields) => ({
      ...prevFields,
      [fieldName]: isFocused,
    }));
  };
  const productAttributes = useMemo(() => {
    if (data?.data.attributeList === undefined) {
      return [];
    }
    const validAttributes = data?.data.attributeList.filter(
      (maybeAttribute): maybeAttribute is ValidProductAttributeWithValue => {
        return isProductAttribute(maybeAttribute) === true;
      }
    );
    return validAttributes;
  }, [data]);

  const getAttributeByKeyName = useCallback(
    (keyName: string): ValidProductAttributeWithValue | undefined => {
      return productAttributes.find((attr) => attr.keyName === keyName);
    },
    [productAttributes]
  );
  // Varshitha commented to implement PLATFORM - 8782 changes 
  // const productIdAttribute = useMemo(() => {
  //   return getAttributeByKeyName("product_id");
  // }, [getAttributeByKeyName]);

  const allAttributeKeyNames = useMemo(() => {
    return productAttributes.map((attr) => attr.keyName);
  }, [productAttributes]);
  const attributesMap = useMemo(() => {
    const attributes: Partial<{ [key in keyof ProductMarketingFormInput]: any }> = {};

    allAttributeKeyNames.forEach((key) => {
      attributes[key as keyof ProductMarketingFormInput] = getAttributeByKeyName(key);
    });

    return attributes;
  }, [allAttributeKeyNames, getAttributeByKeyName]);
  const productIdAttribute = attributesMap["product_id"];
  const productTypeAttribute = attributesMap["product_type"];
  const productNameAttribute = attributesMap["product_name"];
  const productMarketingAttribute = attributesMap["product_marketing_name"];
  const productGenAttribute = attributesMap["genesis_product_name"];
  const productExIdAttribute = attributesMap["product_external_id"];
  const productWrinAttribute = attributesMap["product_wrin_no"];
  const productStatAttribute = attributesMap["ingredient_statement"];
  const productAllAttribute = attributesMap["product_allergen"];
  const productAddAllAttribute = attributesMap["product_additional_allergen"];
  const productCommAttribute = attributesMap["product_comments"];
  const productTextAttribute = attributesMap["product_additional_text_ingredient_statement"];
  const productKeyAttribute = attributesMap["product_keywords"];
  const productThumbImageAttribute = attributesMap["attach_product_thumbnail_image"];
  const productImportAttribute = attributesMap["imported_product_name"];
  const productImIdAttribute = attributesMap["import_id"];
  function isAttributeEditable(attribute: ValidProductAttribute | undefined): boolean {
    return attribute?.isEditable === "Y" ? false : true;
  }
  const isEditableId = isAttributeEditable(productIdAttribute);
  const isEditableName = isAttributeEditable(productNameAttribute);
  const isEditableType = isAttributeEditable(productTypeAttribute);
  const isEditableMarKet = isAttributeEditable(productMarketingAttribute);
  const isEditableGen = isAttributeEditable(productGenAttribute);
  const isEditableExId = isAttributeEditable(productExIdAttribute);
  const isEditableWrin = isAttributeEditable(productWrinAttribute);
  const isEditableState = isAttributeEditable(productStatAttribute);
  const isEditableAll = isAttributeEditable(productAllAttribute);
  const isEditableAddAll = isAttributeEditable(productAddAllAttribute);
  const isEditableComm = isAttributeEditable(productCommAttribute);
  const isEditableText = isAttributeEditable(productTextAttribute);
  const isEditableKey = isAttributeEditable(productKeyAttribute);
  const isEditableThumbImage = isAttributeEditable(productThumbImageAttribute);
  const isEditableImport = isAttributeEditable(productImportAttribute);
  const isEditableImportId = isAttributeEditable(productImIdAttribute);


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<ProductMarketingFormInput>({
    defaultValues: getDefaultValues(productAttributes),
  });

  const handleCancel = () => {
    if (isDirty) {
      setShowUnsavedChangesModal(true);
    } else {
      navigate("/products");
    }
  };

  const handleLeaveNavigation = () => {
    navigate("/products");
  };

  useEffect(() => {
    // Whenever attributes change, update the form's default values
    const defaultValues = getDefaultValues(productAttributes);
    reset(defaultValues);
  }, [productAttributes, reset]);

  const saveProdDetailsRequest = saveProductDetails(apiClient);
  const { isLoading, mutate } = useMutation(
    (data: ProductMarketingFormInput) => {
      const attributesToSave: ProductAttribute[] = [];

      Object.entries(data).forEach(([key, value]) => {
        const attr = productAttributes.find((attr) => attr.keyName === key);
        if (attr) {
          attributesToSave.push({
            ...attr,
            value,
          });
        }
      });
      const metaData = {
        ...(typeof productId !== "undefined"
          ? { productId: Number(productId) }
          : {}),
        countryCode: selectedCountry!,
        roleId: selectedRole!,
        languageCode: language!,
      };
      return saveProdDetailsRequest(attributesToSave, metaData);
    },
    {
      onMutate: () => saveProdDetailsRequest,
      onSuccess: () => {
        setSuccessMessage(saveSuccessMessage);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const onSubmit = (formData: ProductMarketingFormInput) => {
    mutate(formData);
  };

  const renderSimpleField = useCallback(
    (
      fieldName: keyof ProductMarketingFormInput,
      disabled: boolean,
      required: boolean,
      purify: boolean
    ) => {
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (purify) {
          const sanitizedValue = DOMPurify.sanitize(
            event.target.value,
            SANITIZE_OPTS_NO_TAGS
          )
            .toString()
            .trim();
          setValue(fieldName, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={6}>
          <StyledTextField
            fullWidth
            disabled={disabled}
            label={required ? fieldLabel + "*" : fieldLabel}
            InputLabelProps={{ shrink: true }}
            {...register(fieldName, {
              required,
            })}
            onBlur={handleBlur}
            error={!!errors[fieldName]}
            helperText={errors[fieldName] && `${fieldLabel} cannot be empty`}
            data-testid="product-ingredients-input"
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors]
  );

  const renderFocusableField = useCallback(
    (
      fieldName: keyof ProductFocusableInputFields,
      disabled: boolean,
      purify: boolean,
      allowTags: boolean = false
    ) => {
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        handleFieldFocus(fieldName, false);
        if (!purify) {
          setValue(fieldName, event.target.value);
        } else {
          const sanitizedValue = DOMPurify.sanitize(
            event.target.value,
            allowTags ? SANITIZE_OPTS_ALLOW_TAGS : SANITIZE_OPTS_NO_TAGS
          )
            .toString()
            .trim();
          setValue(fieldName, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={12}>
          <StyledTextField
            label={fieldLabel}
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            multiline
            fullWidth
            rows={focusedFields[fieldName] ? 8 : 2}
            {...register(fieldName)}
            error={!!errors[fieldName]}
            data-testid="product-ingredients-textarea"
            onFocus={() => handleFieldFocus(fieldName, true)}
            onBlur={handleBlur}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, focusedFields]
  );
  const renderValidationField = useCallback(
    (
      fieldName: keyof ProductMarketingFormInput,
      disabled: boolean,
      required: boolean,
      validationRules: Record<string, unknown>,
      purify: boolean
    ) => {
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (purify) {
          const sanitizedValue = DOMPurify.sanitize(event.target.value, {
            ALLOWED_TAGS: [],
          })
            .toString()
            .trim();
          setValue(fieldName, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={6}>
          <StyledTextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
            label={required ? fieldLabel + "*" : fieldLabel}
            {...register(fieldName, {
              required,
              ...validationRules,
            })}
            onBlur={handleBlur}
            error={!!errors[fieldName]}
            helperText={errors[fieldName] && errors[fieldName]?.message}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors]
  );

  const renderMinLengthField = useCallback(
    (
      fieldName: keyof ProductMarketingFormInput,
      disabled: boolean,
      required: boolean,
      minLength: number,
      purify: boolean
    ) => {
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (purify) {
          const sanitizedValue = DOMPurify.sanitize(event.target.value, {
            ALLOWED_TAGS: [],
          })
            .toString()
            .trim();
          setValue(fieldName, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={6}>
          <StyledTextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={required ? fieldLabel + "*" : fieldLabel}
            disabled={disabled}
            {...register(fieldName, {
              required,
              validate: (value) => {
                return (
                  value.trim().length >= minLength ||
                  `This field must be at least ${minLength} alphanumeric characters long.`
                );
              },
            })}
            onBlur={handleBlur}
            error={!!errors[fieldName]}
            helperText={errors[fieldName] && errors[fieldName]?.message}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, isReaderRole, editable]
  );

  return (
    <StyledRelativeContainer container spacing={1}>
      {(isFetching || isLoading) && <Loader />}
      <Card>
        <Grid mobile={12} padding={4} paddingTop={0}>
          <StyledTitleBar container mobile={12}>
            <Typography variant="h2">Products & Ingredients</Typography>
            <StyledSecondaryButton
              variant="contained"
              disabled={!isLive}
              onClick={() => {
                navigate(`/products/${productId}/compare-with-live`);
              }}
            >
              Compare with live
            </StyledSecondaryButton>
          </StyledTitleBar>
          <StyledLanguageSelectorWrapper container mobile={12}>
            <LanguageSelector
              selectedLanguage={language}
              disabled={typeof productId === "undefined"}
              onChange={(language) => setLanguage(language)}
            />
          </StyledLanguageSelectorWrapper>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="product-ingredients-form"
          >
            <Grid container spacing={2}>
              {productTypeOptions.length > 0 && (
                <Grid item mobile={12}>
                  <StyledTextField
                    fullWidth
                    label="Product Type*"
                    {...register("product_type", { required: true })}
                    SelectProps={{ native: true }}
                    disabled={!!isReaderRole || !editable || isEditableType}
                    InputLabelProps={{ shrink: true }}
                    select
                    error={!!errors.product_type}
                    helperText={
                      errors.product_type && "Product Type is required"
                    }
                    data-testid="product-ingredients-select"
                  >
                    {productTypeOptions.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </StyledTextField>
                </Grid>
              )}
              {allAttributeKeyNames.includes("product_name") &&
                renderMinLengthField(
                  "product_name",
                  !!isReaderRole || !editable || isEditableName,
                  true,
                  3,
                  true
                )}
              {allAttributeKeyNames.includes("product_marketing_name") &&
                renderSimpleField(
                  "product_marketing_name",
                  !!isReaderRole || !editable || isEditableMarKet,
                  false,

                  true
                )}
              {allAttributeKeyNames.includes("genesis_product_name") &&
                renderSimpleField(
                  "genesis_product_name",
                  !!isReaderRole || !editable || isEditableGen,
                  false,
                  true
                )}
              {allAttributeKeyNames.includes("imported_product_name") &&
                renderMinLengthField(
                  "imported_product_name",
                  !!isReaderRole || !editable ||isEditableImport,
                  true,
                  3,
                  true
                )}
              {productIdAttribute && (
                <Grid item mobile={6}>
                  <StyledTextField
                    fullWidth
                    disabled={isEditableId}
                    label={productIdAttribute.name}
                    value={productIdAttribute.value ?? ""}
                    InputLabelProps={{ shrink: true }}
                    data-testid="product-ingredients-product-id"
                  />
                </Grid>
              )}
              {allAttributeKeyNames.includes("product_external_id") &&
                renderSimpleField(
                  "product_external_id",
                  !!isReaderRole || !editable || isEditableExId,
                  false,
                  true
                )}
              {allAttributeKeyNames.includes("product_wrin_no") &&
                renderValidationField(
                  "product_wrin_no",
                  !!isReaderRole || !editable || isEditableWrin,
                  false,
                  {
                    pattern: {
                      value: /^([Ww][Rr][Ii][Nn]) \d{5}-\d{3}$/,
                      message:
                        "Incorrect WRIN number. Format: <WRIN #####-###> where # is digit",
                    },
                  },
                  true
                )}
              {allAttributeKeyNames.includes("import_id") &&
                renderMinLengthField(
                  "import_id",
                  !!isReaderRole || !editable || isEditableImportId,
                  true,
                  3,
                  false
                )}
              {renderFocusableField(
                "ingredient_statement",
                !!isReaderRole || !editable  || isEditableState,
                true,
                true
              )}
              {renderFocusableField(
                "product_allergen",
                !!isReaderRole || !editable  || isEditableAll,
                true,
                true
              )}
              {renderFocusableField(
                "product_additional_text_ingredient_statement",
                !!isReaderRole || !editable || isEditableText,
                true,
                true
              )}
              {renderFocusableField(
                "product_additional_allergen",
                !!isReaderRole || !editable || isEditableAddAll,
                true,
                true
              )}
              {renderFocusableField(
                "product_comments",
                !!isReaderRole || !editable || isEditableComm,
                true,
                true
              )}
              {renderFocusableField(
                "product_keywords",
                !!isReaderRole || !editable || isEditableKey,
                true
              )}
              <Grid item mobile={12}>
                <StyledTextField
                  label="Attach Thumbnail Image"
                  disabled={!!isReaderRole || !editable  || isEditableThumbImage}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                  rows={2}
                  {...register("attach_product_thumbnail_image", {
                    minLength: 3,
                  })}
                  onBlur={(event) =>
                    setValue(
                      "attach_product_thumbnail_image",
                      DOMPurify.sanitize(event.target.value, {
                        ALLOWED_TAGS: [],
                      })
                        .toString()
                        .trim()
                    )
                  }
                  error={!!errors.attach_product_thumbnail_image}
                  helperText={
                    errors.attach_product_thumbnail_image &&
                    "Thumbnail Image must be at least 3 characters"
                  }
                />
              </Grid>
            </Grid>
            {!isReaderRole && editable && (
              <Grid
                mobile={12}
                sx={{
                  p: 0,
                  my: 4,
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <StyledButton
                  type="submit"
                  variant="contained"
                  size="large"
                  aria-label="Save Product"
                  aria-disabled={!!isReaderRole || !editable}
                >
                  Save
                </StyledButton>
                {!productId ? (
                  <StyledSecondaryButton
                    variant="contained"
                    size="large"
                    aria-label="Reset"
                    disabled={!isDirty}
                    onClick={() => reset()}
                  >
                    Reset
                  </StyledSecondaryButton>
                ) : (
                  <StyledDangerButton
                    variant="contained"
                    size="large"
                    aria-label="cancel"
                    disabled={!isDirty}
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyledDangerButton>
                )}
              </Grid>
            )}
          </form>
        </Grid>
      </Card>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        onLeave={handleLeaveNavigation}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={handleSaveSuccess}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledTitleBar = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  display: "flex",
  justifyContent: "space-between",
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledLanguageSelectorWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  justifyContent: "flex-end",
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
